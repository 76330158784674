<template>
    <div class="app-body">
        <div class="a-flex-rsbc a-ptb-6">
            <span class="a-fs-16 a-fw-700">取水订单</span>
        </div>
        <el-card class="el-main">
            <le-search-form @reset="handlerRest" @search="handlerSearch" :canPackUp="true" id="leSearch">
                <le-company-under-select label="商户" v-model="pageParam.params.companyId"></le-company-under-select>
                <!-- <le-input label="项目" v-model="pageParam.params.incomeName" /> -->
                <le-select-remote-search label="项目" v-model="pageParam.params.incomeId" :options="optionsincomeDistri" placeholder="请选择（可输入搜索）" />
                <le-select-remote-search label="站点" v-model="pageParam.params.stationId" :options="optionsStation" placeholder="请选择（可输入搜索）" />
                <le-date-range 
                    ref="dateRange" 
                    label="下单时间" 
                    :minDate.sync="pageParam.params.startTimeStr" 
                    :maxDate.sync="pageParam.params.endTimeStr" />
                <le-input label="设备编号" v-model="pageParam.params.deviceCode" />
                <jd-select-input :value.sync="searchVal1.value" :selectValue.sync="searchVal1.select" :selectOptions="selectOptions1"></jd-select-input>
                <!-- <jd-select-input :value.sync="searchVal2.value" :selectValue.sync="searchVal2.select" :selectOptions="selectOptions2"></jd-select-input> -->
            </le-search-form>
            <le-pagview ref="waterOrder" @setData="setTableData" :pageParam="pageParam" :isFixed="true" :tableRef="$refs.waterOrderList" >
                <el-table 
                    ref="waterOrderList"
                    :data="tableData" 
                    :highlight-current-row="true" 
                    @filter-change='filterFun'
                    v-sticky="{ top: 0, parent:'.el-card__body' }"
                    style="width: 100%">
                    <el-table-column prop="deviceCode" fixed="left" label="订单编号" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.orderId?row.orderId:'-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="deviceCategroyText" fixed="left" label="用户手机号"  min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.userPhone?row.userPhone:'-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="deviceCategroyText" label="商户"  min-width="140">
                        <template slot-scope="{ row }">
                            <span>{{ row.companyName?row.companyName:'-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column 
                        prop="name" 
                        label="收款商户"
                        min-width="160">
                        <template slot-scope="{ row }">
                            <span>{{ row.payeeCompanyName?row.payeeCompanyName:'-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="deviceCategroyText" label="项目"  min-width="140">
                        <template slot-scope="{ row }">
                            <span>{{ row.incomeDistri?row.incomeDistri:'-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column 
                        prop="name" 
                        label="站点名称"
                        min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.station?row.station:'-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column 
                        prop="name" 
                        label="设备编号"
                        min-width="120">
                        <template slot-scope="{ row }">
                            <span @click="getDeviceInfo(row)" class="font-point a-c-blue">{{ row.deviceCode?row.deviceCode:'-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column 
                        prop="name" 
                        label="出水路数"
                        min-width="120"
                        column-key="waterChannelNo"
                        :filter-multiple='false'
                        :filters="waterChannelNoDic">
                        <template slot-scope="{ row }">
                            <span>{{ row.channelNo | initDic(waterChannelNoDic) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column 
                        prop="name" 
                        label="订单状态"
                        min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.orderStatusName }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column 
                        prop="name" 
                        label="支付状态"
                        min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.payStatusName }}</span>
                        </template>
                    </el-table-column>
                    
                    <el-table-column 
                        prop="name" 
                        label="支付渠道"
                        min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.payChannelName }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column 
                        prop="name" 
                        label="订单金额"
                        min-width="120">
                        <template slot-scope="{ row }">
                            <span>￥{{ util.numFormat(row.orderPrice) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column 
                        prop="name" 
                        label="实付金额"
                        min-width="120">
                        <template slot-scope="{ row }">
                            <span>￥{{ util.numFormat(row.totalPrice) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column 
                        prop="name" 
                        label="下单时间" 
                        min-width="170">
                        <template slot-scope="{ row }">
                            <span>{{ row.createTime?row.createTime:'-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="操作" width="70" fixed="right" >
                        <template slot-scope="scope">
                            
                            <!-- <el-tooltip class="item" effect="dark" content="订单详情" placement="top">
                                <img src="../../assets/icon/option-detail.png" class="a-wh-16" @click="toMoreInfo(scope.row)" />
                            </el-tooltip> -->
                            <el-tooltip class="item a-ml-12" effect="dark" content="退款" placement="top" v-if="userInfo.dataPermission == 99 || company.companyType == 2">
                                <img v-if="scope.row.orderStatus == 2 || scope.row.orderStatus == 1024" src="../../assets/icon/option-refund.png" class="a-wh-16" @click="refound(scope.row)" />
                            </el-tooltip>
                        </template>
                    </el-table-column>
                </el-table>
            </le-pagview>
        </el-card>
    </div>
</template>

<script>
import util from '../../utils/util'
import { mapState } from 'vuex';
let _this
export default {

    data() {
        return {
            util: util,
            tableData: [],
            pageParam: {
                url: this.$Config.apiUrl.waterOrderList,
                method: "post",
                params: {
                    companyId: '',
                    incomeId: '',
                    startTimeStr: '',
                    endTimeStr: '',
                    deviceCode: '',
                    phone: '',
                    orderId: '',
                    stationName: '',
                    stationId: '',
                    channelNo: ''
                },
                freshCtrl: -1,
            },
            optionsincomeDistri: {
                url: this.$Config.apiUrl.incomeDistriList,
                method: "post",
                params: {},
                showLoading: false,
                labelKey: "name",
                valueKey: "id",
                searchKey: "name",
            },//项目数据
            optionsStation: {
                url: this.$Config.apiUrl.getStationInfoList,
                method: "post",
                params: {},
                showLoading: false,
                labelKey: "name",
                valueKey: "id",
                searchKey: "searchKey",
            },//站点数据
            searchVal1: {
                value: '',
                select: '1'
            },
            selectOptions1: [{
                label: '用户手机',
                value: '1'
            },{
                label: '订单编号',
                value: '2'
            }],
            searchVal2: {
                value: '',
                select: '1'
            },
            selectOptions2: [{
                label: '站点名称',
                value: '1'
            },{
                label: '站点ID',
                value: '2'
            }],
            payChannelDic: [],//支付渠道字典
            orderStatusDic: [],//订单状态字典
            payStatusDic: [],//支付状态字典
            waterChannelNoDic: [],//出水路数字典
            slotDic: [],//
        };
    },
    computed:{
        ...mapState({
            company: state => state.company.company,
            userInfo: state => state.user.user,
        })
    },
    created () {
        this.$getDic('payChannel').then(res=>{ this.payChannelDic = res; })
        this.$getDic('orderStatus').then(res=>{ this.orderStatusDic = res; })
        this.$getDic('payStatus').then(res=>{ this.payStatusDic = res; })
        this.$getDic('waterChannelNo').then(res=>{ this.waterChannelNoDic = res; })
    },
    activated () {
        let fromPath = window.sessionStorage.getItem('fromPath') // 当从设备状态列表跳转过来时，设备code入参取路由参数，且重置其他参数。否则保持其缓存状态
        window.sessionStorage.removeItem('fromPath')
        this.$nextTick(()=>{
            if(this.$route.query.id && fromPath == 'finance-szls-list'){
                this.pageParam.params = {
                    companyId: '',
                    incomeId: '',
                    startTimeStr: '',
                    endTimeStr: '',
                    deviceCode: '',
                    phone: '',
                    orderId: this.$route.query.id,
                    stationName: '',
                    stationId: '',
                    channelNo: ''
                };
                this.searchVal1 = {
                    value: this.$route.query.id,
                    select: '2'
                }
                console.log(this.pageParam.params);
                this.$refs['waterOrderList'].clearSelection()
                this.handlerSearch()
            }else{
		        this.pageParam.freshCtrl++;
            }
        })
	},
    watch: {
        searchVal1:{
            deep: true,
            immediate:true,
            handler (val) {
                this.pageParam.params.phone = val.select==1?val.value:''
                this.pageParam.params.orderId = val.select==2?val.value:''
            }     
        },
        searchVal2:{
            deep: true,
            immediate:true,
            handler (val) {
                this.pageParam.params.stationName = val.select==1?val.value:''
                this.pageParam.params.stationId = val.select==2?val.value:''
            }     
        }
    },
    methods: {
        setTableData(data) {
            this.tableData = data;
        },
        handlerRest() {
            this.searchVal1 = {
                value: '',
                select: '1'
            }
            this.searchVal2 = {
                value: '',
                select: '1'
            }
            this.pageParam.params = {
                companyId: '',
                incomeId: '',
                startTimeStr: '',
                endTimeStr: '',
                deviceCode: '',
                phone: '',
                orderId: '',
                stationName: '',
                stationId: '',
                channelNo: ''
            };
            this.$refs['waterOrderList'].clearFilter()
            this.handlerSearch()
        },
        handlerSearch() {
            this.$refs['waterOrder'].pageNum = 1
            this.pageParam.freshCtrl++;
        },
        // 订单退款
        refound (datas) {
            this.$confirm(`是否确认为订单：${datas.orderId}退款？`,'温馨提示')
            .then(_=>{
                this.$Axios._post({
                    url: this.$Config.apiUrl.waterOrderRefund + '/' + datas.orderId,
                    method: "post",
                })
                .then(res=>{
                    if (res.result.code == 0) {
                        this.$message.success('操作成功')
                        this.pageParam.freshCtrl++;
                    } else {
                        
                    }
                })
                .catch(err=>{
            
                })
            })
            .catch(_=>{})
        },
        //条件筛选
        filterFun(value){
            for(var key in value){
                if(key=='orderStatus'){
                    if(value[key].length==0){
                        this.pageParam.params.orderStatus = ''
                    }else{
                        this.pageParam.params.orderStatus = value[key][0]
                    }
                }
                if(key=='payStatus'){
                    if(value[key].length==0){
                        this.pageParam.params.payStatus = ''
                    }else{
                        this.pageParam.params.payStatus = value[key][0]
                    }
                }
                if(key=='waterChannelNo'){
                    if(value[key].length==0){
                        this.pageParam.params.channelNo = ''
                    }else{
                        this.pageParam.params.channelNo = value[key][0]
                    }
                }
            }
            this.handlerSearch()
            this.pageParam.freshCtrl++;
        },
        // 订单信息
        toMoreInfo (datas) {
            this.$router.push({
                path:'/order/package-order-info',
                query: {
                    id: datas?datas.id:''
                }
            })
        },
        // 设备详情
        getDeviceInfo (datas) {
            this.$router.push({
                path: '/device/device-info',
                query:{
                    deviceCode: datas.deviceCode
                }
            })
        },
    },
}
</script>

<style lang="scss" scoped>
/deep/ .s-search-label {
    width: 80px !important;
}
/deep/ .el-table__body-wrapper::-webkit-scrollbar {
    /*width: 0;宽度为0隐藏*/
    width: 0 !important;
    height: 0 !important;
}
/deep/ .el-table__fixed, /deep/ .el-table__fixed-right{
    height: 100% !important;
}
</style>
